// $(document).ready(function () {
//     setTimeout(function () {
//       $(".iti__flag-container").click(function () {
//         $(".iti__flag-container").addClass("open");
//         if ($(".country-dropdown").hasClass("show")) {
//           // The element has the 'show' class
//           $(".country-dropdown").removeClass("show");
//           $(".country-dropdown").addClass("unshow");
// console.log("here1");
//         } else {
//           console.log("here2");
//           // The element doesn't have the 'show' class
//           $(".country-dropdown").removeClass("unshow");
//           $(".country-dropdown").addClass("show");

//         }
//       });
//     }, 1000);
//   });

// $(document).ready(function () {
//   console.log("Document is ready!");
//   setTimeout(function () {
//     $(".iti__flag-container").click(function () {     
//       $(".iti__flag-container").addClass("open");
//       if ($(".country-dropdown").hasClass("show")) {
//         // The element has the 'show' class
//         console.log("1");
//         $(".country-dropdown").removeClass("show");
//         $(".country-dropdown").addClass("unshow");

//       } else {
//         console.log("2");
//         // The element doesn't have the 'show' class
//         $(".country-dropdown").addClass("show");
//         $(".country-dropdown").removeClass("unshow");

//       }
//     });
//   }, 1000);
// });

// $(window).on('onload', function() {
//   $(document).ready(function () {
//    setTimeout(function () {
//     $(".iti__flag-container").click(function () {     
//       $(".iti__flag-container").addClass("open");
//       if ($(".country-dropdown").hasClass("show")) {
//         // The element has the 'show' class    
//         $(".country-dropdown").removeClass("show");
//         $(".country-dropdown").addClass("unshow");

//       } else {       
//         // The element doesn't have the 'show' class
//         $(".country-dropdown").addClass("show");
//         $(".country-dropdown").removeClass("unshow");

//       }
//     });
//    }, 1000);
// })
// })



// $(document).ready(function () {
 
//   setTimeout(function () {
//       $(document).on("click", ".iti__flag-container", function () {
//           $(".iti__flag-container").addClass("open");
//           if ($(".country-dropdown").hasClass("show")) {
//               // The element has the 'show' class
//               $(".country-dropdown").removeClass("show").addClass("unshow");
//           } else {
//               // The element doesn't have the 'show' class
//               $(".country-dropdown").addClass("show").removeClass("unshow");
//           }
//       });
//   }, 1000);
// });

// $(document).ready(function () {
//   setTimeout(function () {
//       $(".iti__flag-container").click(function () {
//           $(".iti__flag-container").toggleClass("open");
//           $(".country-dropdown").toggleClass("show");
//       });
//   }, 1000);
// });
